.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__profiles_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    max-width: 770px;
    
    .p-text{
      text-align: justify;
      text-justify: inter-character;
    }

  }

  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
  
    img {
      width: 100%;
      height: 170px;
      border-radius: 15px;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
  }

  .app__about-btn {
    width: 90%;

    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;

    margin: 1rem 2rem;

    div{
      
      margin: 0 1.5rem;

      button {
        width: 150px;
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary-color);
    
        font-weight: 500;
        color: var(--white-color);
        text-align: center;
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
    
        transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        cursor: pointer;
    
        &:hover {
          background-color: #2430af;
        }

        a {

          color: var(--white-color);

          &:link, :visited, :hover{
            text-decoration: none;
            color: var(--white-color);
          }
        }

      }
    
      @media screen and (max-width: 450px) {
        width: 50%;
        margin: 1rem 1rem;
      }

    }

    @media screen and (max-width: 450px) {
     
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem 2rem;
     
    }
  
  }